import type {FlightPassengerItem} from "~/types/flight/passenger/FlightPassengerItem";
import type {LocationQuery, LocationQueryRaw} from "#vue-router";

export const PassengerTypeCount = (passengers: FlightPassengerItem[]): {
    adlCount: number;
    chdCount: number;
    infCount: number;
    totalCount: number;
    text: string;
} => {
    const adlCount = passengers.filter((passenger: FlightPassengerItem) => passenger.type.code === 'adl').length;
    const chdCount = passengers.filter((passenger: FlightPassengerItem) => passenger.type.code === 'chd').length;
    const infCount = passengers.filter((passenger: FlightPassengerItem) => passenger.type.code === 'inf').length;

    const parts = [];

    if (adlCount > 0) {
        parts.push(`${adlCount} بزرگسال`);
    }
    if (chdCount > 0) {
        parts.push(`${chdCount} کودک`);
    }
    if (infCount > 0) {
        parts.push(`${infCount} نوزاد`);
    }

    return {
        adlCount,
        chdCount,
        infCount,
        totalCount: passengers.length,
        text: parts.join('، ') // Join the parts with a comma
    };
};
export const getPassengerCountFromQuery = (query: LocationQuery) => {
    const adlCount = query.adl ? parseInt(<string>query.adl, 10) || 1 : 1;
    const chdCount = query.chd ? parseInt(<string>query.chd, 10) || 0 : 0;
    const infCount = query.inf ? parseInt(<string>query.inf, 10) || 0 : 0;

    const parts = [];

    if (adlCount > 0) {
        parts.push(`${adlCount} بزرگسال`);
    }
    if (chdCount > 0) {
        parts.push(`${chdCount} کودک`);
    }
    if (infCount > 0) {
        parts.push(`${infCount} نوزاد`);
    }

    return {
        adlCount,
        chdCount,
        infCount,
        totalCount: adlCount + chdCount + infCount,
        text: parts.join("، "), // خروجی متنی مشابه `PassengerTypeCount`
    };
};
