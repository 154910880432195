import type {TripType} from "~/types/flight/search/TripType";
import type {CabinType} from "~/types/flight/search/CabinType";
import type {FlightSearchForm} from "~/types/flight/search/FlightSearchForm";
import type {FlightCity} from "~/types/flight/city/FlightCity";
import type {FlightSearchDate} from "~/types/flight/search/FlightSearchDate";
import type {FlightPassengerItem} from "~/types/flight/passenger/FlightPassengerItem";
import type {PassengerType} from "~/types/flight/passenger/PassengerType";
import type {GenderType} from "~/types/flight/passenger/GenderType";
import type {LocationQueryRaw} from "#vue-router";
import {PassengerTypeCount} from "~/utils/flight/passenger/PassengerTypeCount";
import type {CityAirport} from "~/types/flight/city/CityAirport";
import {allStatusType} from "~/utils/base/common-utils";
import type {FormError} from "#ui/types";
import {convertPersianToGregorian} from "~/utils/calendar/persian-utils";
import {convertGregorianToPersian} from "~/utils/calendar/gregorian-utils";
import {MakePassenger, typeByCode} from "~/utils/flight/passenger/MakePassenger";
import type {SeoItem} from "~/types/seo/SeoItem";
import type {FlightTypeItem} from "~/types/flight/FlightTypeItem";

export const tripTypes = (): TripType[] => {
    return [
        {
            name: 'trip',
            fa_name: 'یک طرفه',
            code: 0
        },
        {
            name: 'roundTrip',
            fa_name: 'رفت و برگشت',
            code: 1
        }
    ]
}
export const defaultTripType = (): TripType => tripTypes()[0]
export const cabinTypes = (): CabinType[] => ([
    {
        name: 'all',
        fa_name: 'همه',
        code: 'A',
        number: 100
    },
    {
        name: 'economy',
        fa_name: 'اکونومی',
        code: 'Y',
        number: 1
    },
    {
        name: 'premium economy',
        fa_name: 'پریمیوم اکونومی',
        code: 'S',
        number: 2
    },
    {
        name: 'business',
        fa_name: 'بیزینس',
        code: 'C',
        number: 3
    },
    {
        name: 'premium business',
        fa_name: 'پریمیوم بیزینس',
        code: 'J',
        number: 4
    },
    {
        name: 'first class',
        fa_name: 'فرست کلس',
        code: 'F',
        number: 5
    },
    {
        name: 'premium first class',
        fa_name: 'پریمیوم فرست کلس',
        code: 'P',
        number: 6
    }
])
export const defaultCabinType = (): CabinType => cabinTypes()[0]
export const flightTypes = (): FlightTypeItem[] => ([
    {
        name_en: "system",
        name_fa: "سیستمی"
    },
    {
        name_en: "charter",
        name_fa: "چارتری"
    }
])
export const defaultAirport = (): CityAirport => ({
    name_en: '',
    name_fa: '',
    iata_code: '',
    domestic_flight: allStatusType()[0],
    foreign_flight: allStatusType()[1],
    icao_code: '',
    id: 0,
    is_active: allStatusType()[0],
    is_popular: allStatusType()[1]
})
export const defaultDate = (): FlightSearchDate => ({
    start_date: {persian: '', gregorian: ''},
    end_date: {persian: '', gregorian: ''}
})
export const defaultPassengers = (): FlightPassengerItem[] => [{
    type: defaultPassengerType(),
    gender: defaultGenderType()
}]
export const defaultPassengerType = (): PassengerType => ({name: 'adult', fa_name: 'بزرگسال', code: 'adl'})
export const defaultGenderType = (): GenderType => ({name: 'male', fa_name: 'مرد', code: 'Mr', number: 1})

export const makeSearch = (
    origin: CityAirport = defaultAirport(),
    destination: CityAirport = defaultAirport(),
    date: FlightSearchDate = defaultDate(),
    tripType: TripType = defaultTripType(),
    flightType: FlightTypeItem | null = null,
    cabin_type?: CabinType | null,
    passengers: FlightPassengerItem[] = defaultPassengers(),
): FlightSearchForm => {
    const form = {
        origin: origin,
        destination: destination,
        date: date,
        tripType: tripType,
        passengers: passengers,
    } as FlightSearchForm
    if (cabin_type)
        form.cabin_type = cabin_type
    if (flightType)
        form.flight_type = flightType
    return form
}

export const buildFlightSearchPathAndQuery = (form: FlightSearchForm) => {
    const query: LocationQueryRaw = {
        date: form.date.start_date.persian,
        adl: PassengerTypeCount(form.passengers).adlCount,
        chd: PassengerTypeCount(form.passengers).chdCount,
        inf: PassengerTypeCount(form.passengers).infCount
    };

    if (form.date.end_date.persian) {
        query.return_date = form.date.end_date.persian;
    }
    if (form.cabin_type)
        query.cabin_type = form.cabin_type.code
    if (form.flight_type)
        query.flight_type = form.flight_type.name_en

    const path = `/flight/${form.origin.iata_code}-${form.destination.iata_code}`;

    return {path, query};
};

export const handleFlightSearch = (form: FlightSearchForm, target: boolean = true): void => {
    const {path, query} = buildFlightSearchPathAndQuery(form);
    const options = target ? {
        open: {
            target: '_blank',
        }
    } : {}
    navigateTo({path, query}, options);
};

export const flightSearchValidate = (state: FlightSearchForm): FormError[] => {
    const errors = []
    if (!state.origin || !state.origin.iata_code) errors.push({path: `origin`, message: 'انتخاب مبدا اجباری است'});
    if (!state.destination || !state.destination.iata_code) errors.push({
        path: `destination`,
        message: 'انتخاب مقصد اجباری است'
    });
    if (state.origin && state.origin.iata_code && state.destination && state.destination.iata_code && state.origin.iata_code === state.destination.iata_code) errors.push({
        path: `origin`,
        message: 'مبدا و مقصد نمی تواند یکسان باشد'
    });
    if (!state.date.start_date.persian) errors.push({path: `start_date`, message: 'تاریخ رفت را انتخاب کنید'});
    if (state.tripType.name === 'round' && !state.date.end_date.persian) errors.push({
        path: `start_date`,
        message: 'تاریخ برگشت را انتخاب کنید'
    });
    return errors;
}

export const getFlightSearchDate = (
    routeQuery: Record<string, any>,
    defaultGregorianStartDate: string
): FlightSearchDate => {
    const {date, return_date} = routeQuery;

    if (date) {
        return {
            start_date: {
                persian: date, // Already in Persian
                gregorian: convertPersianToGregorian(date), // Convert Persian to Gregorian
            },
            end_date: {
                persian: return_date || '', // Empty string if return_date is not provided
                gregorian: return_date ? convertPersianToGregorian(return_date) : '', // Convert if return_date exists, else ''
            },
        };
    }

    // Fallback to defaultGregorianStartDate and ensure return_date is empty
    return {
        start_date: {
            persian: convertGregorianToPersian(defaultGregorianStartDate),
            gregorian: defaultGregorianStartDate,
        },
        end_date: {
            persian: '', // Return date is empty
            gregorian: '', // Return date is empty
        },
    };
};

export const generatePassengers = (query: { adl: number; chd?: number; inf?: number }): FlightPassengerItem[] => {
    // Parse passenger counts with default values
    const adlCount = query.adl !== undefined ? Math.max(parseInt(query.adl as any, 10), 0) : 0;
    const chdCount = query.chd !== undefined ? Math.max(parseInt(query.chd as any, 10), 0) : 0;
    const infCount = query.inf !== undefined ? Math.max(parseInt(query.inf as any, 10), 0) : 0;


    return [
        ...Array(adlCount).fill(null).map(() => MakePassenger(typeByCode('adl'))), // Adults
        ...Array(chdCount).fill(null).map(() => MakePassenger(typeByCode('chd'))), // Children
        ...Array(infCount).fill(null).map(() => MakePassenger(typeByCode('inf'))), // Infants
    ];
};

export const setCabinBasedOnSeo = (seo: SeoItem): CabinType => {
    const cabinRelation = seo.seo_relations.find(
        ({relation_type}) => relation_type === 'cabin'
    )

    return (cabinRelation?.model as CabinType) || defaultCabinType()
}

export const setFlightType = (seo: SeoItem): FlightTypeItem | null => {
    const cabinRelation = seo.seo_relations.find(
        ({relation_type}) => relation_type === 'flight_type'
    )

    return (cabinRelation?.model as FlightTypeItem) || null
}

export const getCabinByCode = (cabinCode: string) => cabinTypes().find(({code}) => code === cabinCode) as CabinType
export const getFlightTypeByName = (name: string) => flightTypes().find(({name_en}) => name_en === name) as FlightTypeItem

interface FlightQueryParams {
    cabin_type?: string
    flight_type?: string
}

export const getFlightQuote = ({cabin_type, flight_type}: FlightQueryParams): {
    message: string,
    title: string
} | null => {
    const selectedCabin = cabin_type ? getCabinByCode(cabin_type) : null
    const selectedFlight = flight_type ? getFlightTypeByName(flight_type) : null

    // اگر هیچ فیلتری اعمال نشده باشد، نیازی به پیام نیست
    if ((!selectedCabin || selectedCabin.code === 'A') && !selectedFlight) {
        return null
    }
    let title = ''
    let message = 'نتایج بر اساس جستجوی شما فیلتر شده‌اند: در این لیست فقط '

    if (selectedFlight) {
        message += `پرواز ${selectedFlight.name_fa} `
        title += `${selectedFlight.name_fa} `
    }
    if (selectedCabin && selectedCabin.code !== 'A') {
        if (selectedFlight)
            message += `که کلاس پروازی ${selectedCabin.fa_name} دارند `
        else
            message += ` کلاس پروازی ${selectedCabin.fa_name} `
        title += `${selectedCabin.fa_name}`
    }

    message += 'نمایش داده می‌شود. برای مشاهده همه پروازها، گزینه "نمایش همه" را انتخاب کنید.'

    return {
        message,
        title: title?.split(' ').join(' - ') || ''
    }
}

