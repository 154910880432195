import { useNuxtApp } from '#app';
import type {MonthInterface} from "~/types/MonthInterface";

export const getPersianDaysInMonth = (
    year: number,
    month: number,
    selectedStartDate: string | null,
    selectedEndDate: string | null,
    expirationDate: string | null,
    disableExpiredDates: boolean
) => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    const days = [];

    // Get the first day of the month and adjust for Persian calendar (Saturday start)
    const firstDay = jalaliMoment(`${year}-${month + 1}-01`, 'jYYYY-jMM-jDD').day();
    const adjustedFirstDay = (firstDay + 1) % 7;  // Adjust to start on Saturday
    const totalDays = jalaliMoment(`${year}-${month + 1}-01`, 'jYYYY-jMM-jDD').jDaysInMonth();

    // Parse today's date
    const today = jalaliMoment();
    const todayFormatted = today.format('jYYYY-jMM-jDD');

    // Parse expiration date or use today as the fallback
    const expiration = expirationDate
        ? jalaliMoment(expirationDate, 'jYYYY-jMM-jDD')
        : today;

    // Add empty slots before the first day of the month
    for (let i = 0; i < adjustedFirstDay; i++) {
        days.push({
            label: '',
            date: null,
            selected: false,
            inRange: false,
            isStart: false,
            isEnd: false,
            isEmpty: true,
            isExpired: false,
        });
    }

    // Generate days in the month
    for (let i = 1; i <= totalDays; i++) {
        const dateMoment = jalaliMoment(`${year}-${month + 1}-${i}`, 'jYYYY-jMM-jDD');
        const date = dateMoment.format('jYYYY-jMM-jDD');

        const isExpired = disableExpiredDates && dateMoment.isBefore(expiration, 'day');
        const isStart = date === selectedStartDate;
        const isEnd = date === selectedEndDate;

        const inRange = selectedStartDate && selectedEndDate
            ? dateMoment.isBetween(
                jalaliMoment(selectedStartDate, 'jYYYY-jMM-jDD'),
                jalaliMoment(selectedEndDate, 'jYYYY-jMM-jDD'),
                'day',
                '[]'
            )
            : false;

        days.push({
            label: i,
            date,
            selected: isStart || isEnd,
            isStart,
            isEnd,
            inRange,
            isEmpty: false,
            isExpired,
        });
    }

    return days;
};


// Get Persian months names
export const getPersianMonths = () => {
    return useNuxtApp().$jalaliMoment().localeData().jMonths(); // Persian month names (Farvardin, Ordibehesht, etc.)
};

// Get Persian weekdays (e.g., Sat, Sun)
export const getPersianWeekdays = () => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    const weekdays = jalaliMoment().localeData().weekdaysMin(); // Fetch short weekdays (e.g., ش, ی, د)
    return [weekdays[6], weekdays[0], weekdays[1], weekdays[2], weekdays[3], weekdays[4], weekdays[5]]; // Reorder to start with Saturday
};

export const getCompletePersianWeekdays = () => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    const weekdays = jalaliMoment().localeData().weekdays(); // Full weekdays (e.g., شنبه, یک‌شنبه)
    return [weekdays[6], weekdays[0], weekdays[1], weekdays[2], weekdays[3], weekdays[4], weekdays[5]]; // Reorder to start with Saturday
};


// Check if a Persian date is before another
export const isPersianDateBefore = (date1: string, date2: string) => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    return jalaliMoment(date1, 'jYYYY-jMM-jDD').isBefore(jalaliMoment(date2, 'jYYYY-jMM-jDD'), 'day');
};

// Check if a Persian date is within a range
export const isPersianDateInRange = (date: string, startDate: string, endDate: string) => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    return jalaliMoment(date, 'jYYYY-jMM-jDD').isBetween(
        jalaliMoment(startDate, 'jYYYY-jMM-jDD'),
        jalaliMoment(endDate, 'jYYYY-jMM-jDD'),
        'day',
        '[]'
    );
};

// Format Persian date to a readable format
export const formatPersianDate = (date: string) => {
    return useNuxtApp().$jalaliMoment(date, 'jYYYY-jMM-jDD').format('jYYYY-jMM-jDD');
};

export const formatPersianDateWithoutYear = (date: string, format:string = 'jMM/jDD') => {
    return useNuxtApp().$jalaliMoment(date, 'jYYYY-jMM-jDD').format(format); // Month-Day format
};

// Parse Persian date into year, month, and day
export const parsePersianDate = (date: string) => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    return {
        year: jalaliMoment(date, 'jYYYY-jMM-jDD').jYear(),
        month: jalaliMoment(date, 'jYYYY-jMM-jDD').jMonth(),
        day: jalaliMoment(date, 'jYYYY-jMM-jDD').jDate(),
    };
};

// Add days to a Persian date
export const addDaysToPersianDate = (date: string, days: number) => {
    return useNuxtApp().$jalaliMoment(date, 'jYYYY-jMM-jDD').add(days, 'days').format('jYYYY-jMM-jDD');
};

// Subtract days from a Persian date
export const subtractDaysFromPersianDate = (date: string, days: number) => {
    return useNuxtApp().$jalaliMoment(date, 'jYYYY-jMM-jDD').subtract(days, 'days').format('jYYYY-jMM-jDD');
};

// Get the current Persian date
export const getCurrentPersianDate = () => {
    return useNuxtApp().$jalaliMoment().format('jYYYY-jMM-jDD');
};

// Check if a Persian date is valid
export const isValidPersianDate = (date: string) => {
    return useNuxtApp().$jalaliMoment(date, 'jYYYY-jMM-jDD').isValid();
};

export const convertPersianToGregorian = (date: string) => {
    const moment = useNuxtApp().$jalaliMoment;
    return moment(date, 'jYYYY-jMM-jDD').locale('en').format('YYYY-MM-DD');
};

// Get Persian date in a flexible format
export const formatPersianDateFlexible = (
    date: string,
    includeYear: boolean = false,
    includeWeekday: boolean = false,
    customFormat: string | null = null
) => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    const momentDate = jalaliMoment(date, 'jYYYY-jMM-jDD');

    // If a custom format is provided, use it
    if (customFormat) {
        return momentDate.format(customFormat);
    }

    // Otherwise, build the default format dynamically
    let format = '';

    if (includeWeekday) format += 'dddd، '; // Add Persian weekday (e.g., پنجشنبه)
    format += 'jD jMMMM'; // Day and month (e.g., 26 مهر)
    if (includeYear) format += ' jYYYY'; // Add year if needed (e.g., 1403)

    return momentDate.format(format);
};


interface month {
    number: number
    name: string
    fa_name: string
}


export const getMonthsList = (): month[] => ([
    {number: 1, name: "Farvardin", fa_name: "فروردین"},
    {number: 2, name: "Ordibehesht", fa_name: "اردیبهشت"},
    {number: 3, name: "Khordad", fa_name: "خرداد"},
    {number: 4, name: "Tir", fa_name: "تیر"},
    {number: 5, name: "Mordad", fa_name: "مرداد"},
    {number: 6, name: "Shahrivar", fa_name: "شهریور"},
    {number: 7, name: "Mehr", fa_name: "مهر"},
    {number: 8, name: "Aban", fa_name: "آبان"},
    {number: 9, name: "Azar", fa_name: "آذر"},
    {number: 10, name: "Dey", fa_name: "دی"},
    {number: 11, name: "Bahman", fa_name: "بهمن"},
    {number: 12, name: "Esfand", fa_name: "اسفند"},
])

// Arrow function for getDaysOfMonth
export const getDaysOfMonth = (month: month | null = null): number[] => {
    const daysInJalaliMonths: Record<number, number> = {
        1: 31,
        2: 31,
        3: 31,
        4: 31,
        5: 31,
        6: 31,
        7: 30,
        8: 30,
        9: 30,
        10: 30,
        11: 30,
        12: 29,
    };

    // If a specific month object is provided
    if (month && month.number) {
        if (month.number === 12) {
            // Check if the current year is a leap year
            const currentYear = new Date().getFullYear(); // Replace with Jalali year if needed
            const isLeapYear = isJalaliLeapYear(currentYear);
            return Array.from({length: isLeapYear ? 30 : 29}, (_, i) => i + 1);
        }

        // Return the days of the provided month
        return Array.from({length: daysInJalaliMonths[month.number]}, (_, i) => i + 1);
    }

    // Default: Return days from 1 to 31
    return Array.from({length: 31}, (_, i) => i + 1);
};

// Arrow function for isJalaliLeapYear
export const isJalaliLeapYear = (year: number): boolean => {
    // Based on Jalali leap year rules
    const a = (year - (year > 0 ? 474 : 473)) % 2820;
    return (((a + 38) * 682) % 2816) < 682;
};


interface PassengerType {
    name: string;
    fa_name: string;
    code: string; // "adl", "chd", or "inf"
}

interface PassengerEntry {
    passenger_type?: PassengerType | null;
    month?: number | null; // Jalali month (1–12)
    day?: number | null;   // Jalali day (1–31)
}

export const getNextYearsRange = (count: number = 30): number[] => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    const currentYear = parseInt(jalaliMoment().jYear().toString(), 10);
    return Array.from({ length: count }, (_, i) => currentYear + i);
};

export const getPreviousYearsRange = (count: number = 30): number[] => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    const currentYear = parseInt(jalaliMoment().jYear().toString(), 10);
    return Array.from({ length: count }, (_, i) => currentYear - i);
};

export const getYearsForPassengerType = (passengerTypeCode: string, isIranianPassenger: boolean = true): number[] => {
    const { $jalaliMoment, $moment } = useNuxtApp();

    // Get the current year based on the calendar type
    const currentYear = isIranianPassenger
        ? parseInt($jalaliMoment().jYear().toString(), 10) // Jalali year
        : parseInt($moment().year().toString(), 10); // Gregorian year

    // Handle rules for different passenger types
    if (passengerTypeCode === "adl") {
        const minYear = currentYear - 132; // Minimum age: 132 years old
        const maxYear = currentYear - 12; // Maximum age: 12 years old
        return Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i).sort((a, b) => b - a);
    }

    if (passengerTypeCode === "chd") {
        const minYear = currentYear - 12; // Maximum age: 12 years
        const maxYear = currentYear - 2; // Minimum age: 2 years
        return Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i).sort((a, b) => b - a);
    }

    if (passengerTypeCode === "inf") {
        const minYear = currentYear - 2; // Maximum age: 2 years
        return Array.from({ length: currentYear - minYear + 1 }, (_, i) => minYear + i).sort((a, b) => b - a);
    }

    // Default fallback: return empty array for invalid passenger types
    return [];
};


export const convertObjectDateToGregorian = (date: {
    day: number | string | null;
    month: MonthInterface | {};
    year: number | string | null;
}): string | null => {
    if (!date.year || !date.day || !date.month || !("number" in date.month)) {
        return null;
    }

    const { year, day, month } = date;

    // Construct the Persian date in the 'jYYYY-jMM-jDD' format
    const persianDate = `${year}-${month.number.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    // Use the existing utility to convert to Gregorian
    return convertPersianToGregorian(persianDate);
};

// Get the number of nights between two Persian dates
export const getNightsCountBetweenDates = (startDate: string, endDate: string): number | null => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;

    const start = jalaliMoment(startDate, 'jYYYY-jMM-jDD');
    const end = jalaliMoment(endDate, 'jYYYY-jMM-jDD');

    if (!start.isValid() || !end.isValid()) {
        return null;
    }

    const differenceInDays = end.diff(start, 'days');

    return differenceInDays > 0 ? differenceInDays : 0;
};

export const formatPersianDateLong = (date: string, format = 'jYYYY/jMM/jDD'): string => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    return jalaliMoment(date, 'jYYYY-jMM-jDD').format(format);
};

// Get month text day and month for Persian dates
export const getMonthTextDayAndMonth = (startDate: string, endDate: string | null) => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;

    // Parse the start and end dates
    const start = jalaliMoment(startDate, 'jYYYY-jMM-jDD');
    const end = endDate ? jalaliMoment(endDate, 'jYYYY-jMM-jDD') : null;

    // Format the start date
    const startDay = start.jDate(); // Day
    const startMonth = start.format('jMMMM'); // Month name in Persian

    // If end date is provided, format it
    if (end) {
        const endDay = end.jDate();
        const endMonth = end.format('jMMMM');

        // If the month of start and end dates are the same
        if (startMonth === endMonth) {
            return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
        }

        // If months differ
        return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
    }

    // If no end date, return only the start date
    return `${startDay} ${startMonth}`;
};


export const getToday = (format: string = 'jYYYY-jMM-jDD') => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;
    const today = jalaliMoment(); // Today's date as a moment object
    return today.format(format); // Formatted today's date
}
export const isPersianDateExpired = (
    date: string,
    expirationDate?: string // optional custom expiration
): boolean => {
    const jalaliMoment = useNuxtApp().$jalaliMoment;

    const targetDate = jalaliMoment(date, 'jYYYY-jMM-jDD');
    const compareTo = expirationDate
        ? jalaliMoment(expirationDate, 'jYYYY-jMM-jDD')
        : jalaliMoment(); // default: compare with today

    return targetDate.isBefore(compareTo, 'day');
};
