import type {FlightPassengerItem} from "~/types/flight/passenger/FlightPassengerItem";
import type {PassengerType} from "~/types/flight/passenger/PassengerType";
import {defaultGenderType} from "~/utils/flight/search-utils";

export const MakePassenger = (type: PassengerType): FlightPassengerItem => {
    return {
        type: type,
        gender: defaultGenderType(),
    }
}

export const typeByCode = (code: 'adl' | 'chd' | 'inf'): PassengerType => {
    if (code === 'adl') return {
        code: code,
        fa_name: 'بزرگسال',
        name: 'adult'
    }
    else if (code === 'chd') return {
        code: code,
        fa_name: 'کودک',
        name: 'child'
    }
    else
        return {
            code: code,
            fa_name: 'نوزاد',
            name: 'infant'
        }
}