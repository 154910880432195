import { useNuxtApp } from '#app';

const DEFAULT_TIMEZONE = 'Asia/Tehran';

// Get the days in a specific Gregorian month
export const getGregorianDaysInMonth = (year: number, month: number, selectedStartDate: string | null, selectedEndDate: string | null, expirationDate: string | null, disableExpiredDates: boolean) => {
    const moment = useNuxtApp().$moment;
    const days = [];
    const firstDay = moment(`${year}-${month + 1}-01`, 'YYYY-MM-DD').day();
    const totalDays = moment(`${year}-${month + 1}-01`, 'YYYY-MM-DD').daysInMonth();
    const today = moment().format('YYYY-MM-DD');
    const expiration = expirationDate ? moment(expirationDate, 'YYYY-MM-DD') : moment(today, 'YYYY-MM-DD');

    // Empty slots before the first day of the month
    for (let i = 0; i < firstDay; i++) {
        days.push({
            label: '',
            date: null,
            selected: false,
            inRange: false,
            isStart: false,
            isEnd: false,
            isEmpty: true,
            isExpired: false,
        });
    }

    // Actual days
    for (let i = 1; i <= totalDays; i++) {
        const date = moment(`${year}-${month + 1}-${i}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const isExpired = disableExpiredDates && moment(date, 'YYYY-MM-DD').isBefore(expiration, 'day');
        const isStart = date === selectedStartDate;
        const isEnd = date === selectedEndDate;
        const inRange =
            selectedStartDate &&
            selectedEndDate &&
            moment(date, 'YYYY-MM-DD').isBetween(
                moment(selectedStartDate, 'YYYY-MM-DD'),
                moment(selectedEndDate, 'YYYY-MM-DD'),
                'day',
                '[]'
            );

        days.push({
            label: i,
            date,
            selected: isStart || isEnd,
            isStart,
            isEnd,
            inRange,
            isEmpty: false,
            isExpired,
        });
    }

    return days;
};


// Get Gregorian months names
export const getGregorianMonths = () => {
    return useNuxtApp().$moment().localeData().months();
};

// Get Gregorian weekdays (e.g., Mon, Tue)
export const getGregorianWeekdays = () => {
    return useNuxtApp().$moment().localeData().weekdaysMin();
};

// Check if a Gregorian date is before another
export const isGregorianDateBefore = (date1: string, date2: string) => {
    const moment = useNuxtApp().$moment;
    return moment(date1, 'YYYY-MM-DD').isBefore(moment(date2, 'YYYY-MM-DD'), 'day');
};

// Check if a Gregorian date is within a range
export const isGregorianDateInRange = (date: string, startDate: string, endDate: string) => {
    const moment = useNuxtApp().$moment;
    return moment(date, 'YYYY-MM-DD').isBetween(
        moment(startDate, 'YYYY-MM-DD'),
        moment(endDate, 'YYYY-MM-DD'),
        'day',
        '[]'
    );
};

// Format Gregorian date to a readable format
export const formatGregorianDate = (date: string) => {
    return useNuxtApp().$moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD');
};

// Parse Gregorian date into year, month, and day
export const parseGregorianDate = (date: string) => {
    const moment = useNuxtApp().$moment;
    return {
        year: moment(date, 'YYYY-MM-DD').year(),
        month: moment(date, 'YYYY-MM-DD').month(),
        day: moment(date, 'YYYY-MM-DD').date(),
    };
};

// Add days to a Gregorian date
export const addDaysToGregorianDate = (date: string, days: number) => {
    return useNuxtApp().$moment(date, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD');
};

// Subtract days from a Gregorian date
export const subtractDaysFromGregorianDate = (date: string, days: number) => {
    return useNuxtApp().$moment(date, 'YYYY-MM-DD').subtract(days, 'days').format('YYYY-MM-DD');
};

// Get the current Gregorian date
export const getCurrentGregorianDate = () => {
    return useNuxtApp().$moment().format('YYYY-MM-DD');
};

// Check if a Gregorian date is valid
export const isValidGregorianDate = (date: string) => {
    return useNuxtApp().$moment(date, 'YYYY-MM-DD').isValid();
};

export const convertGregorianToPersian = (date: string, timezone: string = DEFAULT_TIMEZONE) => {
    const $moment = useNuxtApp().$moment; // Access moment-timezone instance
    const jalaliMoment = useNuxtApp().$jalaliMoment; // Access jalali-moment instance

    // Parse the Gregorian date with timezone
    const gregorianDate = $moment.tz(date, 'YYYY-MM-DD', timezone).toDate();

    // Convert to Jalali
    return jalaliMoment(gregorianDate).locale('fa').format('jYYYY-jMM-jDD');
};


